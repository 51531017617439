.h-background > p {
  position: absolute;
  width: 401px;
  height: 152px;
  left: 101px;
  top: 379px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 50px;
  line-height: 150%;

  letter-spacing: -0.05em;

  color: #161616;
}
.h-background > img {
  max-width: 100%;
}

.h-background > span {
  width: 162px;
  height: 18px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 114%;

  color: #4d4d4d;

  flex: none;
  order: 1;
  flex-grow: 0;
}

.h-video {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  position: absolute;
  width: 237px;
  height: 75px;
  left: 93px;
  top: 599px;
}

.h-icon > img {
  width: 75px;
  height: 75px;
  flex: none;
  order: 0;
  flex-grow: 0;
}
