.products {
  position: absolute;
  width: 1023px;
  height: 619px;
  left: 209px;
  top: 1158px;
}

.p-heading {
  position: absolute;
  width: 187px;
  height: 25px;
  left: 425px;
  top: -138px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 82%;
  color: #4e4e4e;
}

.p-major {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-major > div:nth-of-type(1) {
  position: absolute;
  width: 500px;
  height: 268px;
  left: 1px;
  top: 18px;

  background: #f1f1f1;
  border-radius: 30px;
}
.p-major > div:nth-of-type(2) {
  position: absolute;
  width: 500px;
  height: 268px;
  left: 552px;
  top: 18px;

  background: #f1f1f1;
  border-radius: 30px;
}

.p-major > div:nth-of-type(3) {
  position: absolute;
  width: 500px;
  height: 268px;
  left: 1px;
  top: 409px;

  background: #f1f1f1;
  border-radius: 30px;
}
.p-major > div:nth-of-type(4) {
  position: absolute;
  width: 500px;
  height: 268px;
  left: 552px;
  top: 409px;

  background: #f1f1f1;
  border-radius: 30px;
}
