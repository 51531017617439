.navigation {
  position: absolute;
  width: 1228px;
  height: 114px;
  left: 106px;
  top: 76px;
  background: #ffffff;
  box-shadow: 0px 11px 14px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
}

.logo {
  position: absolute;
  width: 107.18px;
  height: 39px;
  left: 150px;
  top: 40px;
}

.nav-menu {
  flex: 10;
}

.nav-menu > ul {
  display: flex;
  gap: 2rem;
  margin-right: 4rem;
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 15.32px;

  position: absolute;
  width: 328.91px;
  height: 24.8px;
  left: 821.09px;
  top: 28px;
}
