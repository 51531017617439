.services {
  position: absolute;
  width: 100%;
  height: 148px;
  left: 0px;
  top: 876px;
  background: #ffffff;
  box-shadow: -5px 14px 4px -6px rgba(88, 88, 88, 0.14);
}

.s-heading {
  position: absolute;
  width: 133px;
  height: 20px;
  left: 626px;
  top: -3px;
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 82%;
  color: #4e4e4e;
}

.s-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  gap: 65.42px;
  position: absolute;
  width: 702.66px;
  height: 11px;
  left: 352px;
  top: 90px;
  flex: 10;
}

.s-links > ul {
  display: flex;
  gap: 2rem;
  margin-right: 4rem;
  cursor: pointer;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0px;
  gap: 15.32px;
  position: absolute;
  width: 328.91px;
  height: 24.8px;
  left: 191.09px;
  top: -28px;
}

.s-links > ul > li {
  gap: 60;
}
