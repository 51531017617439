.w-header {
  position: absolute;
  width: 352px;
  height: 25px;
  left: 542px;
  top: 1976px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 105%;
  /* or 25px */

  color: #4e4e4e;
}

.w-video {
  position: absolute;
  width: 431.6px;
  height: 365px;
  left: 502.4px;
  top: 2030px;
}

.partner {
  position: absolute;
  width: 219px;
  height: 25px;
  left: 654px;
  top: 2666px;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 105%;
  color: #4e4e4e;
}

.p-frame1 {
  position: absolute;
  width: 658.05px;
  height: 76.56px;
  left: -223px;
  top: 40px;
}

.p-frame2 {
  position: absolute;
  width: 658.05px;
  height: 76.56px;
  left: -223px;
  top: 240px;
}
